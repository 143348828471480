import { httpGet, httpPost } from "../api";
import { contactRequestLimit } from "../config/constants";

const contactService = {
  getConversionList(cursor = null, limit = contactRequestLimit) {
    return httpGet("/m_im/conversation_list", { cursor, limit });
  },
  getServiceUserCount() {
    return httpGet("/m_im/service_user_count");
  },
  queryUsers(payload) {
    return httpGet("/m_im/query_users", payload);
  },
  createConversation(real_user_id) {
    const payload = { real_user_id }
    return httpPost("/m_im/create_conversation", payload)
  }
};

export default contactService;
