import { message, message as Message } from "antd";
import { ImUserName } from "../config/constants";

import { initChatInfo, initHistoryChatInfo } from "./init";
import {
  START_CHAT_WITH,
  LOAD_MORE_MESSAGE,
  NEW_CHAT_MESSAGE,
  START_HISTORY_CHAT,
  LOAD_MORE_HISTORY_MESSAGE,
  UPDATE_CHAT_CONVERSATION_STATUS
} from "./action-type";
import { getDateTime, decodeMessage } from "../utils";

import {
  AdminId,
  messageRequestLimit,
  noMoreDataText
} from "../config/constants";
import chatService from "../service/message";
// import websocket from "../config/websocket";

export const moreMessageCount = 20;

const showLoading = Message.loading;
let hide = null;

const websocketSend = (msg_type = "txt",chat,str,is_read = false) => {
  const { wsService } = require("./WebSocketRedux");
  wsService.sendMessage(msg_type,chat,str,is_read);
};

export const chat = (state = initChatInfo, action) => {
  const { type, data } = action;
  switch (type) {
    case START_CHAT_WITH:
      return { ...state, ...data };
    case NEW_CHAT_MESSAGE:
      // 收到新消息的时候也更新会话状态
      const interlocutor = { ...state.interlocutor };
      interlocutor.conversation_status = 'in_progress'
      state.interlocutor = interlocutor;
      state.message_list.push(data);
      return { ...state };
    case LOAD_MORE_MESSAGE:
      let message_list = state.message_list;
      if (data.message_list) {
        message_list = [...data.message_list, ...message_list];
      }
      return { ...state, ...data, message_list };
    case UPDATE_CHAT_CONVERSATION_STATUS:
      let updatemessage = data[0] && data[0];
      // 是同一个用户的时候才更新状态
      if (updatemessage.real_im_username === state.real_im_username) {
        const interlocutor = { ...state.interlocutor };
        interlocutor.conversation_status = 'finish'
        state.interlocutor = interlocutor;
        return { ...state };
      }
    default:
      return state;
  }
};

export const historyChat = (state = initHistoryChatInfo, action) => {
  const { type, data } = action;
  switch (type) {
    case START_HISTORY_CHAT:
      return { ...state, ...data };
    case LOAD_MORE_HISTORY_MESSAGE:
      let message_list = state.message_list;
      if (data.message_list) {
        message_list = [...data.message_list, ...message_list];
      }
      return { ...state, ...data, message_list };
    default:
      return state;
  }
};


export const ChatActions = {
  startChatWith: (contact) => {
    return async (dispatch) => {
      const interlocutor = { id: contact.real_user_info.id, nickname: contact.real_user_info.nickname, conversation_status: contact.conversation_status };
      dispatch({
        type: START_CHAT_WITH,
        data: {
          interlocutor,
          real_im_username: contact.real_im_username,
          conversation_id: contact.conversation_id,
          loading: true,
          message_list: [],
          no_more_data: "",
          cursor: null
        }
      });
      const result = await chatService.getHistoryChatMessageList(contact.real_im_username);
      if (!result) return;
      const { cursor, items } = result;
      if (!items) return;
      items.forEach((item) => {
        item.date_time = getDateTime(item.time_stamp);
        item = decodeHistoryMessageItem(item);
      });
      items.reverse();
      dispatch({
        type: LOAD_MORE_MESSAGE,
        data: {
          cursor,
          message_list: items,
          loading: false,
          no_more_data:
            items.length === messageRequestLimit ? "" : noMoreDataText
        }
      });
      websocketSend(
        "txt",
        {
          conversation_id: contact.conversation_id,
          real_im_username: contact.real_im_username, 
        },
        "",
        true
      );
    };
  },
  onNewMessage: (msg) => {
    return (dispatch) => {
      dispatch({ type: NEW_CHAT_MESSAGE, data: msg });
      if (msg.from !== localStorage.getItem(ImUserName)) {
        websocketSend(
          "txt",
          {
            conversation_id: msg.ext.conversation_id,
            real_im_username: msg.from 
          },
          "",
          true
        );
      }
    };
  },
  loadMoreChatMessage: (loadMoreCursor = null, im_username, cb) => {
    return async (dispatch) => {
      dispatch({
        type: LOAD_MORE_MESSAGE,
        data: { loading: true }
      });
      const result = await chatService.getHistoryChatMessageList(
        im_username,
        loadMoreCursor
      );
      if (!result) return;
      const { cursor, items } = result;
      if (!items) return;
      items.forEach((item) => {
        item.date_time = getDateTime(item.time_stamp);
        item = decodeHistoryMessageItem(item);
      });
      cb && cb();
      if (items.length === 0) {
        dispatch({
          type: LOAD_MORE_MESSAGE,
          data: {
            loading: false,
            no_more_data: noMoreDataText
          }
        });
        return;
      }
      items.reverse();
      dispatch({
        type: LOAD_MORE_MESSAGE,
        data: {
          loading: false,
          cursor,
          message_list: items
        }
      });
    };
  },
  onSendMessage: (msg_type = "txt",chat,msgStr, cb) => {
    hide = showLoading("loading...", 0);
    return async (dispatch) => {
      await websocketSend(msg_type,chat,msgStr,false);
      hide();
      cb && cb();
      dispatch({ type: "default" });
    };
  },
  updateChatMessageConversationStatus: (data, cb) => {
    return (dispatch) => {
      dispatch({ type: UPDATE_CHAT_CONVERSATION_STATUS, data });
    };
  }
};

export const ChatHistoryActions = {
  startHistoryChatWith: (im_username) => {
    return async (dispatch) => {
      dispatch({
        type: START_HISTORY_CHAT,
        data: {
          loading: true,
          message_list: [],
          no_more_data: "",
          cursor: null
        }
      });
      const result = await chatService.getHistoryChatMessageList(im_username);
      if (!result) return;
      const { cursor, items } = result;
      if (!items) return;
      items.forEach((item) => {
        item.date_time = getDateTime(item.time_stamp);
        item = decodeHistoryMessageItem(item);
      });
      items.reverse();
      dispatch({
        type: LOAD_MORE_HISTORY_MESSAGE,
        data: {
          cursor,
          message_list: items,
          loading: false,
          no_more_data:
            items.length === messageRequestLimit ? "" : noMoreDataText
        }
      });
    };
  },
  loadMoreHistoryChatMessage: (loadMoreCursor = null, im_username, cb) => {
    return async (dispatch) => {
      dispatch({
        type: LOAD_MORE_HISTORY_MESSAGE,
        data: { loading: true }
      });
      const result= await chatService.getHistoryChatMessageList(
        im_username,
        loadMoreCursor
      );
      if (!result) return;
      const { cursor, items } = result;
      if (!items) return;
      items.forEach((item) => {
        item.date_time = getDateTime(item.time_stamp);
        item = decodeHistoryMessageItem(item);
      });
      cb && cb();
      if (items.length === 0) {
        dispatch({
          type: LOAD_MORE_HISTORY_MESSAGE,
          data: {
            loading: false,
            no_more_data: noMoreDataText
          }
        });
        return;
      }
      items.reverse();
      dispatch({
        type: LOAD_MORE_HISTORY_MESSAGE,
        data: {
          loading: false,
          cursor,
          message_list: items
        }
      });
    };
  }
};


// 处理消息解密
const decodeMessageItem = (message) => {
  // 解密消息内容
  let content = "";
  if (message.body.type === "txt") {
    // 文字消息
    content = message.body.msg ? decodeMessage(message.body.msg) : "";
    message.body.msg = content;
  }else{
    // 图片消息
    content = message.body.image ? decodeMessage(message.body.image) : "";
    message.body.image = content;
  }
  if (message.from !== localStorage.getItem(ImUserName)) {
    const user_id = decodeMessage(message.ext.user_id);
    const avatar_url = decodeMessage(message.ext.avatar_url);
    const nickname = decodeMessage(message.ext.nickname);
    message.ext.user_id = user_id;
    message.ext.avatar_url = avatar_url;
    message.ext.nickname = nickname;
  }

  return message
}

// 处理历史消息解密
const decodeHistoryMessageItem = (message) => {
  // 解密消息内容
  let content = "";
  if (message.body.type === "txt") {
    // 文字消息
    content = message.body.msg ? decodeMessage(message.body.msg) : "";
    message.body.msg = content;
  } else {
    // 图片消息
    content = message.body.image ? decodeMessage(message.body.image) : "";
    message.body.image = content;
  }
  return message
}