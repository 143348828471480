import React, { Component, Fragment } from "react";
import { Input, Select, Button, Modal, Row, Space, Col, List, Avatar, message } from "antd";
import { UserOutlined } from '@ant-design/icons';
import contactService from "../../../service/contact";

const { Search } = Input
const { Option } = Select

export default class QueryModal extends Component {
  state = {
    results: [],
    queryParam: "user_id"
  }

  updateState = (key, value) => {
    const payload = {
      [key]: value
    }
    this.setState(payload)
  }

  queryUsers = async (queryParam, value) => {
    if (value === "") {
      this.setState({ results: [] })
      return
    }
    const hide = message.loading("搜寻中...", 0)
    const payload = {
      [queryParam]: value
    }
    const data = await contactService.queryUsers(payload)
    hide()
    if (Object.keys(data).length === 0) {
      this.setState({ results: [] })
      return
    }
    const user = {
      id: data.user_id,
      nickname: data.nickname,
      level: `G${data.game_level}${data.is_big_R ? "(大R用户)" : ""}`,
      gender: data.gender === "male" ? "男性" : data.gender === "female" ? "女性" : "",
    }
    this.setState({ results: [user] })
  }

  createConversation = async () => {
    const { results } = this.state
    const { handleCreate } = this.props
    if (results.length === 0) {
      return
    }
    const hide = message.loading("创建对话中...", 0)
    const resp = await contactService.createConversation(results[0].id)
    hide()
    if (Object.keys(resp).length === 0) {
      return
    }
    const { data } = resp
    const { conversation_id } = data
    handleCreate(conversation_id)
  }

  render() {
    const { visible, updateProp } = this.props
    const { results, queryParam } = this.state
    return (
      <Modal visible={visible} footer={null} centered closable={false}>
        < Space style={{ width: "100%" }
        } direction="vertical" size="middle" >
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}><h2>请搜寻欲发布对象</h2></Col>
          </Row>
          <Input.Group compact>
            <Select style={{ width: "30%" }} value={queryParam} onChange={(value) => this.updateState("queryParam", value)} >
              <Option value="user_id">会员ID</Option>
              <Option value="nickname">会员昵称</Option>
              <Option value="invite_code">邀请码</Option>
            </Select>
            <Search style={{ width: "70%" }} onSearch={(value) => this.queryUsers(queryParam, value)} />
          </Input.Group>
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}><h2>搜寻结果</h2></Col>
          </Row>
          <List dataSource={results} locale={{ emptyText: "查无资料" }} bordered={true} renderItem={item => (
            <List.Item key={item.id}>
              <List.Item.Meta
                avatar={<Avatar icon={<UserOutlined />} size="large" style={{
                  verticalAlign: "middle"
                }} />}
                title={
                  <Fragment>
                    <Row>
                      <Col span={12}>会员ID：{item.id}</Col>
                      <Col span={12}>会员昵称：{item.nickname}</Col>
                    </Row>
                    <Row>
                      <Col span={12}>会员等级：{item.level}</Col>
                      <Col span={12}>性别：{item.gender}</Col>
                    </Row>
                  </Fragment>
                }
              >
              </List.Item.Meta>
            </List.Item>
          )} />
          <Row justify="space-evenly">
            <Col span={4}>
              <Button style={{ width: "100%" }} onClick={() => updateProp("queryVisible", false)}>取消</Button>
            </Col>
            {results.length > 0 &&
              <Col span={4}>
                <Button type="primary" style={{ width: "100%" }} onClick={this.createConversation}>发消息</Button>
              </Col>
            }
          </Row>
        </Space>
      </Modal >
    );
  }
}
