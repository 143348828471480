import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Layout, Avatar, Row, Col, Button, Drawer } from "antd";
import HistoryMessage from "./historyMessage"
import { ChatHistoryActions } from "../../../redux/ChatRedux";
import store from "../../../redux/store";
import {
  noMoreDataText
} from "../../../config/constants";
const {  Content } = Layout;

class MessHeader extends Component {
  state = { visible: false, isShowDrawer: false };

  showDrawer = () => {
    this.setState({
      isShowDrawer: true,
    });
    this.props.startHistoryChatWith(this.props.chat.real_im_username);
  };

  onClose = () => {
    this.setState({
      isShowDrawer: false,
    });
  };

  loadMoreHistoryList = () => {
    store.dispatch(
      // 就是当前会话消息
      ChatHistoryActions.loadMoreHistoryChatMessage(this.props.historyChat.cursor, this.props.chat.real_im_username)
      );
  };

  render() {
    const {
      chat: { interlocutor },
      historyChat: { no_more_data }
    } = this.props;
    const { isShowDrawer} = this.state;
    let conversation_status = "进行中";
    if (interlocutor.conversation_status === "finish") {
      conversation_status = "结束"
    }
    const nickname = interlocutor.nickname ? interlocutor.nickname : "";
    const id = interlocutor.id ? interlocutor.id : "";

    return (
      <div>
        <Row>
          <Col span={20}>
            <Avatar
              shape="square"
              size={36}
              style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
            >
              用户
            </Avatar>
            <span
              style={{ fontSize: 18, marginLeft: 10, color: "#000" }}
              dangerouslySetInnerHTML={{ __html : `[${id}] ${nickname}    ${conversation_status}`}}
              />
          </Col>
          {/* <Col span={2}>
            <Button 
              type="small"
              onClick={() => {
                this.showDrawer();
              }}
              >历史记录</Button>
          </Col> */}
        </Row>

        <Drawer
          title="客服聊天历史记录"
          placement="right"
          width={500}
          closable={false}
          onClose={this.onClose}
          visible={isShowDrawer}
          getContainer={false}
          key="right"
        >
          <div>
            <p 
              className="no_more_message"
              onClick={() => this.loadMoreHistoryList()}
              style={no_more_data === noMoreDataText ? { display: "none" } : {  }}
              >点击加载更多数据</p>
            <Content
              className="message-body"
            >
              <HistoryMessage onRef={this.onRef}/>
            </Content>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default connect(
  (state) => ({ chat: state.chat , historyChat: state.historyChat}),
  (dispatch) => ({
    startHistoryChatWith: (real_im_username, onFail) =>
      dispatch(ChatHistoryActions.startHistoryChatWith(real_im_username, onFail))
  })
)(withRouter(MessHeader));
