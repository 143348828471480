import { initUser } from "./init";
import { SET_USER_INFO, UPDATE_USER_STATUE } from "./action-type";
import userService from "../service/user";

export const user = (state = initUser, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return { ...state, ...action.data };
    case UPDATE_USER_STATUE:
      state.userState = action.data.state;
      return { ...state };
    default:
      return state;
  }
};

export const UserActions = {
  userLogin: (loginParam, onSuccess, onFailed) => {
    return async (dispatch) => {
      const { user } = await userService.login(loginParam);
      if (!user) return onFailed && onFailed();
      dispatch({ type: SET_USER_INFO, data: user });
      onSuccess && onSuccess(user.id, user.auth_token, loginParam.nickname);
    };
  },
  updateUserState: (data) => {
    return (dispatch) => {
      dispatch({ type: UPDATE_USER_STATUE, data });
    };
  }
};
