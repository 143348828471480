import { combineReducers } from "redux";
import { user } from "./UserRedux";
import { contact } from "./ContactRedux";
import { service } from "./ContactRedux";
import { chat } from "./ChatRedux";
import { historyChat } from "./ChatRedux";

export default combineReducers({
  user,
  contact,
  chat,
  service,
  historyChat,
  ws: require("./WebSocketRedux")
});
