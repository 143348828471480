import { AdminId, contactRequestLimit } from "../config/constants";
import contactService from "../service/contact";
import { getDateTime, decodeMessage, decodeUserID } from "../utils";

import {
  SET_LOADING,
  SET_CONVERSATION_LIST,
  ADD_NEW_CONTACT,
  UPDATE_EXIST_CONTACT,
  LOAD_MORE_CONVERSATION_LIST,
  UPDATE_SERVICE_USER_COUNT,
  UPDATE_CONVERSATION_STATUS
} from "./action-type";
import { initConversationList, initServiceUserCount } from "./init";

export const service = (state = initServiceUserCount, action) => {
  const { type, data } = action
  switch (type) {
    case UPDATE_SERVICE_USER_COUNT:
      return { ...state, ...data };
    default:
      return state;
  }
};

export const ServiceUserActions = {
  updateServiceUserCount: () => {
    return async (dispatch) => {
      const data = await contactService.getServiceUserCount();
      dispatch({ type: UPDATE_SERVICE_USER_COUNT, data });
    }
  }
}

export const contact = (state = initConversationList, action) => {
  let index = -1;
  const { type, data } = action;
  switch (type) {
    case SET_LOADING:
      return { ...state, loading: true };
    case SET_CONVERSATION_LIST:
      return Object.assign(
        { ...state, loading: false },
        data || initConversationList
      );
    case LOAD_MORE_CONVERSATION_LIST:
      return {
        ...state,
        ...data,
        loading: false,
        items: [...state.items, ...action.data.items]
      };
    case ADD_NEW_CONTACT:
      let message = data[0] && data[0];
      index = state.items.findIndex((item) => item.real_im_username === message.real_im_username);
      if (index > -1) {
        let old_contact = state.items.splice(index, 1)[0];
        if (message.is_cur_chat) {
          message.unread_message_count = 0;
        } else {
          message.unread_message_count = old_contact.unread_message_count + 1;
        }
      }
      state.items.unshift(message);
      index = -1;
      return { ...state };
    case UPDATE_EXIST_CONTACT:
      index = state.items.findIndex((s) => s.real_user_id === data[0].user_id);
      if (index > -1) {
        state.items[index] = { ...state.items[index], ...data[0] };
        index = -1;
      }
      return { ...state };
    case UPDATE_CONVERSATION_STATUS:
      // 更新用户会话状态
      let updatemessage = data[0] && data[0];
      index = state.items.findIndex((item) => item.real_im_username === updatemessage.real_im_username);
      if (index > -1) {
        // 获取到这个用户 并删除掉这个用户
        let old_contact = state.items.splice(index, 1)[0];
        old_contact.conversation_status = "finish"
        // 删除然后重新添加
        state.items.unshift(old_contact);
      }
      index = -1;
      return { ...state };
    default:
      return state;
  }
};

export const ContactActions = {
  getConversionList: () => {
    return async (dispatch) => {
      dispatch({ type: SET_LOADING });
      const data = await contactService.getConversionList();
      if (!data || !data.items) return;
      data.items.forEach((item) => {
        item.date_time = getDateTime(item.last_message.created_time);
        let last_message = item.last_message;
        if (last_message !== null && last_message.message_type === "txt") {
          last_message.message_content = decodeMessage(last_message.message_content);
        }
        item.last_message = last_message;
      });
      if (data.items.length < contactRequestLimit) {
        data["no_more_data"] = true;
      }
      dispatch({ type: SET_CONVERSATION_LIST, data });
    };
  },

  loadMoreConversionList: (cursor = null) => {
    return async (dispatch) => {
      dispatch({ type: SET_LOADING });
      const data = await contactService.getConversionList(cursor);
      if (!data || !data.items) return;
      data.items.forEach((item) => {
        item.date_time = getDateTime(item.last_message.created_time);
        let last_message = item.last_message;
        if (last_message !== null && last_message.message_type === "txt") {
          last_message.message_content = decodeMessage(last_message.message_content);
        }
        item.last_message = last_message;
      });
      if (data.items.length === 0) {
        data["no_more_data"] = true;
      }
      dispatch({ type: LOAD_MORE_CONVERSATION_LIST, data });
    };
  },

  addOrUpdateContactInfo: (msg, isCurChat, isInChat) => {
    return (dispatch) => {
      const created_time = Date.now();
      const date_time = getDateTime(created_time);
      let message = {
        is_cur_chat: isCurChat,
        conversation_id: msg.ext.conversation_id,
        real_im_username: msg.from,
        real_user_id: decodeUserID(msg.ext.user_id),
        created_time: created_time,
        date_time: date_time,
        unread_message_count: 1,
        last_message: {
          message_type: msg.body.type,
          message_content: msg.body.msg,
        },
        real_user_info: {
          id: decodeUserID(msg.ext.user_id),
          nickname: msg.ext.nickname,
          avatar: msg.ext.avatar_url,
          created_time: msg.time_stamp,
          is_big_R: msg.ext.is_big_R
        },
        conversation_status: "in_progress"
      }
      console.log(message);
      dispatch({ type: ADD_NEW_CONTACT, data: [message] });
    };
  },
  updateExistContactInfo: (payload, cb) => {
    return (dispatch) => {
      dispatch({ type: UPDATE_EXIST_CONTACT, data: [payload] });
    };
  },
  updateConversationStatus: (data, cb) => {
    return (dispatch) => {
      dispatch({ type: UPDATE_CONVERSATION_STATUS, data });
    };
  }
};
