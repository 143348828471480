import Login from "../pages/login";
import ChatContainer from "../pages/chat";

const Routers = [
  {
    path: "/",
    components: ChatContainer
  },
  {
    path: "/login",
    components: Login
  }
];

export default Routers;
