import { httpGet } from "../api";
import { messageRequestLimit } from "../config/constants";
import { ImUserName } from "../config/constants";

const chatService = {
  getChatMessageList(conversation_id, cursor = null, limit = messageRequestLimit) {
    return httpGet("/m_im/chat_history", {conversation_id, im_username: localStorage.getItem(ImUserName), cursor, limit });
  },
  getHistoryChatMessageList(im_username, cursor = null, limit = messageRequestLimit) {
    return httpGet("/m_im/chat_history", {im_username, cursor, limit });
  }
};

export default chatService;
