export const MediaHost = "https://consoleapi.00k2s.info/";
export const ApiHost = "https://consoleapi.00k2s.info";
export const ConsoleHost = "https://consoleapi.00k2s.info";

// 媒体解密key
export const decodeMediaKey = "Jui7X#cdleN^3eZb";
export const AdminId = "9999";

// 解密key
export const aesKey = "Uyrgei+CnZ+gefLJ";
// 解密iv
export const aesIv = "1dstF$M5$K7oZ@Pt";

export const ImXUserID = "im_x_user_id";
export const ImXAuthToken = "im_x_auth_token";
export const Im_Nickname = "im_x_nick_name";
export const messageRequestLimit = 10;
export const contactRequestLimit = 10;
export const noMoreDataText = "没有更多数据了";


export const ImUserName = "im_username";
export const ImPassWord = "password";
export const MqttUrl = "mqtt_url";
// 消息类型
export const chatType = "cs";

export const contentsType = {
  text: "txt",
  image: "image"
};

// 消息状态
export const messageType = {
  in_progress: "in_progress",
  is_read: "is_read"
};
