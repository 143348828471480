import React, { Component } from "react";
import { Layout } from "antd";

import { throttle } from "../../../utils";

import MessHeader from "./header";
import Message from "./message";
import ChatTextarea from "./textarea";

const { Header, Content, Footer } = Layout;

export default class MessageLayout extends Component {
  messageEl = null;

  onRef = (ref) => {
    this.messageEl = ref;
  };

  handleScroll = throttle((target) => {
    this.messageEl.containerOnScroll(target.scrollTop);
  }, 300);

  render() {
    return (
      <div className="message-container">
        <Header className="message-header">
          <MessHeader />
        </Header>
        <Content
          className="message-body"
          onScroll={(e) => this.handleScroll(e.target)}
        >
          <Message onRef={this.onRef} />
        </Content>
        <Footer className="message-footer">
          <ChatTextarea />
        </Footer>
      </div>
    );
  }
}
