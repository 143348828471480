/**
 * 包含n个工具函数的模块
 */
import aesjs from "aes-js";
import base64 from "base-64";
import URLSafeBase64 from "urlsafe-base64";
import md5 from "js-md5";
import { message } from "antd";
import { ImXAuthToken } from "../config/constants";

import { MediaHost, decodeMediaKey, aesKey, aesIv } from "../config/constants";

export const throttle = (fun, delay) => {
  let last, deferTimer;
  return function (args) {
    let that = this;
    let _args = arguments;
    let now = +new Date();
    if (last && now < last + delay) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        fun.apply(that, _args);
      }, delay);
    } else {
      last = now;
      fun.apply(that, _args);
    }
  };
};

export const getDateTime = (timestamp) => {
  // const date = new Date(timestamp);
  // const thisYear = new Date(Date.now()).getFullYear();
  // const curMonth = new Date(Date.now()).getMonth() + 1;
  // const today = new Date(Date.now()).getDate();

  // let t = Number(timestamp);
  // let y = new Date(t).getFullYear();
  // let mo = new Date(t).getMonth() + 1;
  // let d = new Date(t).getDate();
  // let h = new Date(t).getHours();
  // let mi = new Date(t).getMinutes();
  // let s = new Date(t).getSeconds();

  // y = y === thisYear ? "" : `${y}/`;
  // if (mo === curMonth && d === today) {
  //   mo = d = "";
  // } else {
  //   mo = mo > 9 ? mo : `0${mo}`;
  //   d = d > 9 ? d : `0${d}`;
  // }
  // h = h > 9 ? h : `0${h}`;
  // mi = mi > 9 ? mi : `0${mi}`;
  // s = s > 9 ? s : `0${s}`;

  // return `${y}${mo}/${d} ${h}:${mi}:${s}`;
  return new Date(timestamp).toLocaleString();
};

export const sortContactList = (list, sortKey) => {
  const compare = (key) => {
    return (m, n) => {
      return n[key] - m[key];
    };
  };
  list.sort(compare(sortKey));
};

export const onGetMessageFaild = () => {
  message.error("拉取历史消息失败");
};

window.aesjs = aesjs;

export const decodePicture = (file_id) => {
  let res_url = "";
  return new Promise(async (resolve, reject) => {
    const key = aesjs.utils.utf8.toBytes(decodeMediaKey);
    const aesEcb = new aesjs.ModeOfOperation.ecb(key);
    const url = `${MediaHost}${file_id}`;
    fetch(url, {
      headers: {
        "X-AUTH-TOKEN": localStorage.getItem(ImXAuthToken),
        "Cache-Control": "public, max-age=31536000"
      }
    })
    .then((res) => res.blob())
    .then((blob) => {
      // console.log(blob)
      let reader = new FileReader();
      reader.readAsArrayBuffer(blob);
      reader.addEventListener("loadend", () => {
        // reader.result 包含转化为类型数组的blob
        let arr = new Uint8Array(reader.result);

        try {
          let decryptedBytes = aesEcb.decrypt(arr);
          let blob = new Blob([decryptedBytes], {
            type: "image/*"
          }); // 传入一个合适的MIME类型
          res_url = URL.createObjectURL(blob);
          resolve(res_url);
        } catch (e) {
          resolve(url);
        }
      });
    });
  });
};

/**
 * 1. base64 解码为字符串
 * 2. 字符串转换为ascii码数组
 * 3. 解密
 * 4. 从字节转换为字符串
 *
 * 1. 字符串（部位）转换为字节
 * 2. 加密
 * 3. ascii码数组转换为字符串
 * 4. base64 编码
 *
 */

function stringToHex(str) {
  var arr = [];
  for (var i = 0; i < str.length; i++) {
    arr.push(str.charCodeAt(i));
  }
  return arr;
}

function intFromBytes(arr) {
  const buf = Buffer.from(arr);
  return buf.readUInt32BE(0);
}

export const encryptMessage = (data) => {
  let key = aesjs.utils.utf8.toBytes(aesKey);
  let iv = aesjs.utils.utf8.toBytes(aesIv);

  let dataBytes = aesjs.utils.utf8.toBytes(data);

  let bytes = aesjs.padding.pkcs7.pad(dataBytes);

  var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
  var encryptedBytes = aesCbc.encrypt(bytes);
  let encryptedHex = String.fromCharCode(...encryptedBytes);

  return base64.encode(encryptedHex);
};

export const decodeMessage = (data) => {
  let key = aesjs.utils.utf8.toBytes(aesKey);
  let iv = aesjs.utils.utf8.toBytes(aesIv);
  let str = base64.decode(data);

  let resArr = stringToHex(str);

  let aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
  let decryptedBytes = aesCbc.decrypt(resArr);

  let result = aesjs.utils.utf8.fromBytes(decryptedBytes);
  return result.replace(/[\x00-\x0f]/g, "");
};

export const reConfused = (str) => {
  let bytes = URLSafeBase64.decode(str);

  let s1 = bytes.slice(0, 4);
  let s2 = bytes.slice(-4);

  s1 = md5.digest(s1).slice(0, 4);

  return intFromBytes(s2) - intFromBytes(s1);
};

export const randomString = (
  length = 32,
  chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
) => {
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

/**
 * 处理get请求的可选参数
 * 遍历对象，删除无效值的键
 * @param {Object} obj 需要遍历的对象
 */
export const deleteInvalidKey = (obj) => {
  for (let key in obj) {
    let val = obj[key];
    if (!val && val !== 0 && typeof val !== "boolean") delete obj[key];
  }
  return obj;
};

export const clickCopy = (content, tips) => {
  tips = tips || "复制成功";
  var input = document.createElement("input");
  input.setAttribute("readonly", "readonly");
  input.setAttribute("value", content);
  document.body.appendChild(input);
  input.focus();
  input.setSelectionRange(0, 9999);
  if (document.execCommand("copy")) {
    document.execCommand("copy");
    // message.success(tips);
  }
  document.body.removeChild(input);
};

export const decodeUserID = (user_id) => {
  const crypto = require('crypto')

  const headBytes = 4
  const trailBytes = 4

  // 檢查負數情況
  if (user_id.startsWith('-') && /^[0-9]+$/.test(user_id.substring(1))) {
    return parseInt(user_id, 10)
  }

  // 處理 URL 編碼
  if (user_id.includes('%')) {
    user_id = decodeURIComponent(user_id.replace(/\+/g, ' '))
  }

  // Base64 解碼
  let segBytes = Buffer.from(user_id, 'base64')

  // 分割字節數組
  let seg1 = segBytes.slice(0, headBytes)
  let seg2 = segBytes.slice(headBytes)

  // 重新計算 seg1 的哈希
  seg1 = crypto.createHash('md5').update(seg1).digest().slice(0, trailBytes)

  // 計算最終 ID
  let seg2Int = byteArrToInt(seg2)
  let seg1Int = byteArrToInt(seg1)

  return seg2Int - seg1Int

  function byteArrToInt(byteArr) {
    let result = 0
    for (let i = 0; i < byteArr.length; i++) {
      result = (result << 8) + byteArr[i]
    }
    return result
  }
}