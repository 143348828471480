import React, { Component } from "react";
import { List, Skeleton, Avatar, Badge, Spin, Input, Select } from "antd";
import { connect } from "react-redux";
import VirtualList from "react-tiny-virtual-list";
import InputSearch from "./search";
import QueryModal from "./query";

import { ContactActions, ServiceUserActions } from "../../../redux/ContactRedux";
import { ChatActions } from "../../../redux/ChatRedux";
import { throttle, decodeMessage } from "../../../utils";
import "./contact.css";
import iconBigR from "../../../assets/images/iconBigR.png";

const colorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae", "#87d068"];

class Contacts extends Component {
  state = {
    ruleBigR: -1,
    readStatus: -1,
    nickname: "",
    queryVisible: false
  };

  updateServiceUserCount = () => {
    this.props.updateServiceUserCount();
  };

  getConversionList = () => {
    this.props.getConversionList();
  };

  componentDidMount() {
    this.getConversionList();
    this.updateServiceUserCount();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const conversation_id = sessionStorage.getItem("conversation_id")
    if (conversation_id !== null) {
      const { contact } = this.props;
      let index = contact.items.findIndex(item => item.conversation_id === conversation_id)
      if (index > -1) {
        sessionStorage.clear()
        this.showMessage(contact.items[index])
      }
    }
  }

  renderItem = ({ style, index }, contactList) => {
    const { real_im_username } = this.props.chat;
    const item = contactList[index];
    const unread_message_count = item.unread_message_count || 0;
    const last_message = item.last_message;
    let conversation_status = "进行中";
    if (item.conversation_status === "finish") {
      conversation_status = "结束"
    }
    let description = "";
    if (last_message !== null) {
      description = last_message.message_type === "txt" ? last_message.message_content : "【图片】"
    }
    const peer_nickname = item.real_user_info.nickname ? item.real_user_info.nickname : ""
    const avatar = item.real_user_info.nickname ? item.real_user_info.nickname.slice(-1) : ""
    const is_big_R = item.real_user_info.is_big_R
    return (
      <List.Item
        key={item.real_user_info.id + peer_nickname + index}
        className={item.real_im_username === real_im_username ? "active" : null}
        style={style}
        onClick={() => this.showMessage(item)}
      >
        <Skeleton avatar title={false} loading={!contactList.length} active>
          <List.Item.Meta
            avatar={
              <Badge count={unread_message_count}>
                <Avatar
                  style={{
                    backgroundColor: colorList[index % colorList.length],
                    verticalAlign: "middle"
                  }}
                  size="large"
                >
                  {avatar}
                </Avatar>
                {is_big_R && <img src={iconBigR} className="contact-avatar-bigr" />}
              </Badge>
            }
            title={<span dangerouslySetInnerHTML={{ __html: peer_nickname + "    " + conversation_status}} />}
            description={<span dangerouslySetInnerHTML={{ __html: description }} />}
          />
          <div className="timeDate">{item.date_time}</div>
        </Skeleton>
      </List.Item>
    );
  };

  render() {
    const { contact } = this.props;
    const { ruleBigR, readStatus, nickname, queryVisible } = this.state
    let contactList = this.filterList(contact.items, ruleBigR, readStatus, nickname);

    return (
      <div className="list_container">
        <div className="user-search">
          <InputSearch
            ruleBigR={ruleBigR}
            readStatus={readStatus}
            nickname={nickname}
            updateProp={this.updateState}
          />
        </div>
        <List className="conversation-list">
          <VirtualList
            width="100%"
            height={618}
            itemCount={contactList.length}
            itemSize={70}
            renderItem={(p) => this.renderItem(p, contactList)}
            onItemsRendered={this.onItemsRendered}
          />
        </List>
        {contact.loading && (
          <div className="spin_box">
            <Spin />
          </div>
        )}
        <QueryModal visible={queryVisible} updateProp={this.updateState}
          handleCreate={this.handleCreate}></QueryModal>
      </div>
    );
  }

  updateState = (key, value) => {
    const payload = {
      [key]: value
    }
    this.setState(payload)
  }

  filterList = (list, ruleBigR, readStatus, nickname) => {
    nickname = nickname.toUpperCase()
    let result = [...list]
    switch (readStatus) {
      case 0:
        result = result.filter(item => item.unread_message_count > 0)
        break;
      case 1:
        result = result.filter(item => item.unread_message_count === 0)
        break;
    }
    switch (ruleBigR) {
      case 0:
        result = result.filter(item => !item.real_user_info.is_big_R)
        break;
      case 1:
        result = result.filter(item => item.real_user_info.is_big_R)
        break;
    }
    if (nickname !== "") {
      result = result.filter(item => item.real_user_info.nickname.toUpperCase().includes(nickname))
    }

    result.sort((a, b) => b.last_message.created_time - a.last_message.created_time)

    return result
  }

  showMessage = async (contact) => {
    const { chat } = this.props;
    if (chat.conversation_id === contact.conversation_id) return;
    contact.unread_message_count = 0;
    this.props.startChatWith(contact);
    this.props.setContactHasRead({ user_id: contact.real_user_info.id, unread_message_count: 0 });
  };

  onItemsRendered = throttle(({ startIndex, stopIndex }) => {
    const { contact, loadMoreConversionList } = this.props;
    if (
      !contact.loading &&
      !contact.no_more_data &&
      stopIndex === contact.items.length - 1
    ) {
      loadMoreConversionList(contact.cursor);
    }
  }, 300);

  handleCreate = (conversation_id) => {
    this.setState({
      queryVisible: false,
      ruleBigR: -1,
      readStatus: -1,
      nickname: ""
    }, () => {
      sessionStorage.setItem("conversation_id", conversation_id)
      this.getConversionList()
    })
  }
}

export default connect(
  (state) => ({ chat: state.chat, contact: state.contact, service: state.service }),
  (dispatch) => ({
    getConversionList: () => dispatch(ContactActions.getConversionList()),
    updateServiceUserCount: () => dispatch(ServiceUserActions.updateServiceUserCount()),
    loadMoreConversionList: (cursor) =>
      dispatch(ContactActions.loadMoreConversionList(cursor)),

    setContactHasRead: (payload) =>
      dispatch(ContactActions.updateExistContactInfo(payload)),

    startChatWith: (contact, onFail) =>
      dispatch(ChatActions.startChatWith(contact, onFail))

  })
)(Contacts);
