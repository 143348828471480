import { getWsInstance } from '../config/websocket'
import {
  ImXAuthToken,
  Im_Nickname,
  ImUserName,
  MqttUrl,
  ImPassWord,
  chatType,
  messageType,
  contentsType,
} from '../config/constants'
import mqtt from 'mqtt'
import { ChatActions } from './ChatRedux'
import { UserActions } from './UserRedux'
import history from '../history'

import { ContactActions, ServiceUserActions } from './ContactRedux'
import store from './store'
import { getDateTime, decodeMessage, encryptMessage } from '../utils'
import { httpGet } from '../api'
import {
  SET_CONVERSATION_LIST,
} from './action-type'

export const wsService = {
  // client 
  client: null,
  // 定时器
  timer: null,
  // clientId
  clientId: null,
  // 开启链接
  onOpen: () => {
    console.log('连接成功 ' + new Date())
    store.dispatch(
      // 更新用户状态
      UserActions.updateUserState({ state: 'Online' }),
    )
    if (wsService.client) {
      if (!wsService.timer) {
        wsService.timer = window.setInterval(wsService.getClientStatus, 30 * 1000)
      }
    }
  },
  // 定时器循环检查 当前连接状态
  getClientStatus: () => {
    // 判断连接状态
    if (!wsService.client.connected) {
      // 重新连接的方法
      // 断开mq
      wsService.closeClient()
      // 然后重新请求mq
      wsService.getMqttClient()

      console.log('定时检查，未连接，重连中 ' + new Date())
    }
    else {
      console.log('定时检查当前为已连接 ' + new Date())
    }
  },
  // 重新连接
  onReconnect: () => {
    console.log('重新连接 ' + new Date())
    store.dispatch(
      // 更新用户状态
      UserActions.updateUserState({ state: 'connecting' }),
    )
  },
  // 收到消息
  onMessage: (topic, event) => {
    if (topic == wsService.clientId) {
      // 当前收到的是账号被挤的消息回调
      // 退出登录清空本地消息清空clientId
      // 断开连接
      wsService.closeClient()
      // 清除数据
      wsService.clearData()
      // 回到登录页面
      history.push('/login')
      return
    }
    let message = JSON.parse(event.toString())
    message.date_time = getDateTime(message.time_stamp)
    if (typeof message === 'string') {
      message = JSON.parse(message)
    }
    // 丢弃离线消息
    if (message.message_no) return
    if (message.body.custom_event === 'begin' || message.body.custom_event === 'finish') {
      // 需要刷新消息客服服务用户数
      store.dispatch(
        // 就是当前会话消息
        ServiceUserActions.updateServiceUserCount(),
      )
      if (message.body.custom_event === 'finish') {
        // 刷新列表 根据会话id 查询到当前这个用户 更新 list 里面的 状态
        let finishMessage = { real_im_username: message.from }
        store.dispatch(
          // 就是当前会话消息
          ContactActions.updateConversationStatus([finishMessage]),
        )
        // 获取到所有的消息
        store.dispatch(
          ChatActions.updateChatMessageConversationStatus([finishMessage]),
        )

      }
    }
    // 判断消息 类型 已读消息不处理 直接丢弃
    if (message.body.type === 'txt' || message.body.type === 'image') {
      // 解密消息内容
      let content = ''
      if (message.body.type === 'txt') {
        // 文字消息
        content = message.body.msg ? decodeMessage(message.body.msg) : ''
        message.body.msg = content
      }
      else {
        // 图片消息
        content = message.body.image ? decodeMessage(message.body.image) : ''
        message.body.image = content
      }
      const user_id = message.ext.user_id ? decodeMessage(message.ext.user_id) : ''
      const avatar_url = message.ext.avatar_url ? decodeMessage(message.ext.avatar_url) : ''
      const nickname = message.ext.nickname ? decodeMessage(message.ext.nickname) : ''
      const conversation_id = message.ext.conversation_id ? decodeMessage(message.ext.conversation_id) : ''
      const is_big_R = message.ext.is_big_R === true || message.ext.is_big_R === 1
      message.ext.user_id = user_id
      message.ext.avatar_url = avatar_url
      message.ext.nickname = nickname
      message.ext.conversation_id = conversation_id
      message.ext.is_big_R = is_big_R
      const { chat } = store.getState()
      // 是否是当前聊天
      let isCurChat = chat.real_im_username === message.from
      // 是否是正在聊天的用户
      let isInChat = chat.conversation_id > 0
      if (isCurChat) {
        // 新消息
        store.dispatch(ChatActions.onNewMessage(message, isCurChat, isInChat))
      }
      store.dispatch(
        // 就是当前会话消息
        ContactActions.addOrUpdateContactInfo(message, isCurChat, isInChat),
      )
    }

  },
  // 链接失败
  onError: (err) => {
    store.dispatch(
      // 更新用户状态
      UserActions.updateUserState({ state: 'Offline' }),
    )
    console.log('mqtt connect error observed ', err.toString() + new Date())
  },
  // 订阅主题
  onSubscribe: (err, granted) => {
    if (err) {
      console.log('topic subscription: granted ', granted, err.toString() + ' ' + new Date())
    }
  },
  // 取消订阅主题
  unsubscribe: (err, granted) => {
    if (err) {
      console.log('topic unsubscribe: granted ', granted, err.toString())
    }
  },
  // 监听断开连接的时候出发 监听主动触发
  close: () => {
    store.dispatch(
      // 更新用户状态
      UserActions.updateUserState({ state: 'Offline' }),
    )
    console.log('mqtt client Disconnected ' + new Date())
  },
  // 用户点击退出按钮的时候触发  被动触发
  closeClient: () => {
    if (wsService.client) {
      // 取消订阅
      wsService.client.unsubscribe([localStorage.getItem(ImUserName), wsService.clientId], { qos: 1 },
        wsService.unsubscribe)
      // 关闭连接
      wsService.client.end()
      wsService.client = null
      wsService.clientId = null
      if (wsService.timer) {
        window.clearInterval(wsService.timer)
        wsService.timer = null
      }
    }
  },

  // 清除数据
  clearData: () => {
    //清除 localStorage
    localStorage.clear()
    // 清除会话列表数据
    store.dispatch({ type: SET_CONVERSATION_LIST, data: null })
  },
  // 发送消息
  // chat: 用户相关信息
  // message: 消息内容
  // is_read: 是否已读
  sendMessage: (msg_type, chat, message, is_read) => {
    let msg = ''
    const topic = chat.real_im_username
    if (is_read) {
      // 发送会话已读消息
      msg = getReadMessage(chat)
    }
    else {
      // 发送消息
      msg = getSendMessage(msg_type, chat, message)
    }
    if (wsService.client == null) {
      // 重新如果没有client 重新初始化 mqtt 
      getClient()
    }
    else {
      wsService.client.publish(topic, msg, { qos: 1, retain: false }, function (error) {
        if (error) {
          console.log(error)
        }
        else {
          if (!is_read) {
            // 非已读消息添加到 message_list 里面去
            let fromMessage = JSON.parse(msg)
            fromMessage.created_time = fromMessage.time_stamp
            fromMessage.date_time = getDateTime(fromMessage.time_stamp)
            let content = ''
            if (fromMessage.body.type === 'txt') {
              // 文字消息
              content = decodeMessage(fromMessage.body.msg)
              fromMessage.body.msg = content
            }
            else {
              content = decodeMessage(fromMessage.body.image)
              fromMessage.body.image = content
            }
            store.dispatch(ChatActions.onNewMessage(fromMessage, true, true))
            const payload = {
              user_id: chat.interlocutor.id,
              last_message: {
                message_type: fromMessage.body.type,
                message_content: fromMessage.body.msg,
                created_time: fromMessage.time_stamp,
              },
            }
            store.dispatch(ContactActions.updateExistContactInfo(payload))
          }
          console.log('消息发送成功')
        }
      })
    }
  },
  getMqttClient: () => {
    getClient()
  },
}

// 发送已读消息
const getReadMessage = (chat) => {
  const topic = chat.real_im_username
  const conversation_id = chat.conversation_id
  let cs_message = {
    is_read: 1,
    conversation_id,
  }
  cs_message = JSON.stringify(cs_message)
  cs_message = encryptMessage(cs_message)
  const msg = {
    from: localStorage.getItem(ImUserName),
    to: topic,
    chat_type: chatType,
    offline_send: 0,
    time_stamp: Date.parse(new Date()),
    body: {
      custom_event: messageType.is_read,
      custom_exts: {
        cs_message,
      },
    },
  }
  return JSON.stringify(msg)
}

// 发送消息
const getSendMessage = (msg_type, chat, str) => {
  const topic = chat.real_im_username
  const conversation_id = encryptMessage(chat.conversation_id)
  const message = {
    from: localStorage.getItem(ImUserName),
    to: topic,
    chat_type: chatType,
    time_stamp: Date.parse(new Date()),
    body: {
      type: msg_type,
    },
    ext: {
      conversation_id,
      nickname: encryptMessage(localStorage.getItem(Im_Nickname)),
    },
  }

  if (msg_type === 'txt') {
    message.body.msg = encryptMessage(str)
  }
  else {
    message.body.image = encryptMessage(str)
  }
  return JSON.stringify(message)
}

// 获取 client 对象
const getClient = async () => {
  if (!localStorage.getItem(ImXAuthToken)) return
  if (localStorage.getItem(ImUserName) && localStorage.getItem(ImPassWord) && localStorage.getItem(MqttUrl)) {
    // 直接执行
    // 获取clientID
    let time_stamp_clientID = new Date().getTime()
    const client = mqtt.connect(localStorage.getItem(MqttUrl),
      getmqttOptions(localStorage.getItem(ImUserName), localStorage.getItem(ImPassWord), time_stamp_clientID))
    let clientId = client.options.clientId
    // 监听当前用户自己的消息 监听当前 cliendID的 消息
    client.subscribe([localStorage.getItem(ImUserName), clientId], { qos: 1 }, wsService.onSubscribe)
    // 建立链接
    client.on('connect', wsService.onOpen)
    // 重新连接
    client.on('reconnect', wsService.onReconnect)
    // 链接失败
    client.on('error', wsService.onError)
    // 收到消息
    client.on('message', wsService.onMessage)
    // 在断开连接以后触发
    client.on('close', wsService.close)
    wsService.client = client
    wsService.clientId = clientId
  }
  else {

    // await 走接口获取配置，再写到mqttOptions里
    // await 完成后，
    // 本地没有数据的时候才去请求数据
    const result = await httpGet('/m_im/get_console_im_info')
    if (result.mqtt_url === undefined || result.user_im_account.im_username === undefined ||
      result.user_im_account.password === undefined) return null
    const im_username = result.user_im_account.im_username
    const password = result.user_im_account.password
    const mqtt_url = result.mqtt_url
    localStorage.setItem(ImUserName, im_username)
    localStorage.setItem(ImPassWord, password)
    localStorage.setItem(MqttUrl, mqtt_url)
    // 获取clientID
    let time_stamp_clientID = new Date().getTime()
    // 直接执行
    const client = mqtt.connect(mqtt_url, getmqttOptions(im_username, password, time_stamp_clientID))
    let clientId = client.options.clientId
    // 监听当前用户自己的消息 cliendID的 消息
    client.subscribe([localStorage.getItem(ImUserName), clientId], { qos: 1 }, wsService.onSubscribe)
    // 建立链接
    client.on('connect', wsService.onOpen)
    // 重新连接
    client.on('reconnect', wsService.onReconnect)
    // 链接失败
    client.on('error', wsService.onError)
    // 收到消息
    client.on('message', wsService.onMessage)
    // 在断开连接以后触发
    client.on('close', wsService.close)
    wsService.client = client
    wsService.clientId = clientId
  }
}

// 初始化 mqttOptions
const getmqttOptions = (im_username, password, clientId) => {
  return {
    protocolId: 'MQTT',
    protocolVersion: 4,
    clean: true,
    reconnectPeriod: 10 * 1000,
    keepalive: 30,
    connectTimeout: 30 * 1000,
    will: {
      topic: localStorage.getItem(ImUserName),
      payload: 'Connection Closed abnormally..!',
      qos: 0,
      retain: false,
    },
    rejectUnauthorized: false,
    // Auth
    clientId: clientId,
    username: im_username,
    password: password,
  }
}

getClient()