import axios from "axios";
import { message } from "antd";
import history from "../history";
import { ConsoleHost, ImXAuthToken } from "../config/constants";

const ErrorMessage = {
  1007: "登录过期，请重新登录"
};

axios.defaults.timeout = 30000;
axios.defaults.headers.common["Content-Type"] = "application/json";

axios.interceptors.request.use(
  (config) => {
    config.headers["X-AUTH-TOKEN"] = localStorage.getItem(ImXAuthToken);

    let { url } = config;

    url = url.startsWith("http") ? url : `${ConsoleHost}${url}`;
    config.url = url;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (res) => {
    let response = {};
    if (res.status === 200) {
      let resData = res.data;
      if (resData.status === 0) {
        response = resData.data;
      } else if (resData.status === 1007) {
        message.info(ErrorMessage[resData.status]);
        const { wsService } = require("../redux/WebSocketRedux");
        // 断开连接
        wsService.closeClient();
        // 清除数据
        wsService.clearData();
        history.push("/login");
      } else {
        message.error(`${resData.status} ${resData.message}`);
      }
    } else {
      message.error(res.status);
    }
    return response;
  },
  (err) => {
    message.error(err.message);
  }
);

export default axios;
