/**
 * 包含n个 action type 名称常量
 */
export const INIT_CHAT_INFO = "init_chat_info";
export const INIT_MESS_LIST = "init_mess_list";
export const RECEIVE_CHAT_MSG = "receive_chat_msg";
export const CHANGE_RIGHT_TYPE = "change_right_type";
export const INIT_FRIEND_INFO = "init_friend_info";

export const SET_USER_INFO = "set_user_info";
export const UPDATE_USER_STATUE = "update_user_state";

export const SET_LOADING = "set_loading";
export const SET_CONVERSATION_LIST = "load_more_conversation_list";
export const LOAD_MORE_CONVERSATION_LIST = "set_conversation_list";
export const ADD_NEW_CONTACT = "add_new_contact";
export const UPDATE_CONVERSATION_STATUS = "update_conversation_status";

export const UPDATE_EXIST_CONTACT = "update_exist_contact";
export const SEARCH_CONTACT = "search_contact";

export const START_CHAT_WITH = "start_chat_with";
export const LOAD_MORE_MESSAGE = "load_more_message";
export const NEW_CHAT_MESSAGE = "new_chat_message";
export const UPDATE_CHAT_CONVERSATION_STATUS = "update_chat_conversation_status";

export const START_HISTORY_CHAT = "start_history_chat";
export const LOAD_MORE_HISTORY_MESSAGE = "load_more_history_message";

// 更新服务用户数量
export const UPDATE_SERVICE_USER_COUNT = "update_service_user_count";
