import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip, Avatar, Row, Col, Spin } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { ChatHistoryActions } from "../../../redux/ChatRedux";
import ZImage from "../../../components/commom/ZImage";
import { LoadingOutlined } from "@ant-design/icons"

import "./message.css";

class HistoryMessage extends Component {
  firstMessageItem = null;
  messageContainer = null;
  messageListBox = null;

  isLoadMore = false;

  componentDidMount() {
    const { onRef } = this.props;
    onRef && onRef(this);
  }

  render() {
    const {
      historyChat: { loading, no_more_data }
    } = this.props;

    return (
      <div ref={(el) => (this.messageContainer = el)}>
        {loading && this.spin}
        {no_more_data && <p className="no_more_message">{no_more_data}</p>}
        <div ref={(el) => (this.messageListBox = el)}>{this.getMessList()}</div>
        <div
          ref={(el) => {
            this.messagesEnd = el;
          }}
        />
      </div>
    );
  }

  // loading
  spin = (
    <Spin
      size="small"
      style={{ textAlign: "center", width: "100%", fontSize: 24 }}
      indicator={<LoadingOutlined spin />}
    ></Spin>
  );

  scrollToLastNewMessage = () => {
    if (this.firstMessageItem) {
      this.firstMessageItem.scrollIntoView();
      this.messageContainer.parentElement.scrollTop -= this.firstMessageItem.offsetHeight;
      let timeout = setTimeout(() => {
        this.isLoadMore = false;
        clearTimeout(timeout);
        timeout = null;
      });
    }
  };

  scrollToBottom = (e) => {
    this.isLoadMore = false;
    this.messagesEnd.scrollIntoView();
  };

  containerOnScroll = (scrollTop) => {
    if (scrollTop === 0) {
      const { no_more_data, loading } = this.props.historyChat;
      if (no_more_data || loading) return;
      this.startLoadMore(this.scrollToLastNewMessage);
    }
  };

  // load more
  startLoadMore = (cb) => {
    const {
      chat: { cursor, real_im_username },
      loadMoreMessage,
    } = this.props;
    this.isLoadMore = true;
    loadMoreMessage(cursor, real_im_username, cb);
  };

  // 获取消息列表的 dom 结构
  getMessList = () => {
    const {
      user,
      historyChat: { message_list },
      chat: { interlocutor, real_im_username },
    } = this.props;

    return message_list.map((item, index) => {
      let show_date = false;
      let cur_date = message_list[index]["date_time"].split(" ")[0];
      if (index === 0) {
        show_date = true;
      } else {
        let last_date = message_list[index - 1]["date_time"].split(" ")[0];
        show_date = last_date !== cur_date;
      }
      const peer_nickname = interlocutor.nickname ? interlocutor.nickname.slice(-1) : "";
      if (item.from === real_im_username) {
        return (
          <React.Fragment key={cur_date + index + index}>
            {show_date && <p className="date_item">{cur_date}</p>}
            <div className="message-item message-left">
              <Row>
                <Col span={1} style={{ minWidth: "44px" }}>
                  <Avatar
                    size="default"
                    style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                  >
                    {peer_nickname}
                  </Avatar>
                </Col>
                <Col span={20}>
                  <div className="message">
                    <Tooltip placement="topLeft" title={item.body.msg}>
                      {this.getMessage(item)}
                    </Tooltip>
                  </div>
                </Col>
              </Row>
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={cur_date + index}>
            {show_date && <p className="date_item">{cur_date}</p>}
            <div
              key={cur_date + index}
              className="message-item message-right"
            >
              <div className="pull-left message right_content">
                <Tooltip placement="topRight" title={cur_date}>
                  {this.getMessage(item)}
                </Tooltip>
              </div>
              <div className="pull-right">
                <Avatar style={{ backgroundColor: "#87d068" }} icon={<UserOutlined />} />
              </div>
              <div style={{ clear: "both" }} />
            </div>
          </React.Fragment>
        );
      }
    });
  };

  getMessage = (item) => {
    if (item.body.type === "txt") {
      return (
        <div>
          <span dangerouslySetInnerHTML={{ __html: item.body.msg }} />
        </div>
      );
    } else if (item.body.type === "image") {
      return (
        <div className="message_image_box">
          <ZImage src={item.body.image} className="message_image" />
        </div>
      );
    }
  };
}

export default connect(
  (state) => ({ chat: state.chat, user: state.user, historyChat: state.historyChat }),
  (dispatch) => ({
    loadMoreMessage: (cursor, im_username, cb) =>
      dispatch(ChatHistoryActions.loadMoreHistoryChatMessage(cursor, im_username, cb))
  })
)(HistoryMessage);
