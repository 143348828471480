import { AdminId, ImXAuthToken } from "../config/constants";

// 存储当前登录用户信息
export const initUser = {
  access_token: localStorage.getItem(ImXAuthToken) || "",
  id: "",
  admin_id: AdminId,
  userState: "",
};

export const initServiceUserCount = {
  // 当前服务用户数量
  service_user_count: null,
  // 剩余服务用户数量
  rest_service_user_count: null
};

export const initConversationList = {
  cursor: null,
  items: [],
  search_result: [],
  loading: false,
  no_more_data: false
};

// 存储聊天信息等
export const initChatInfo = {
  loading: false,
  conversation_id: -1,
  real_im_username: "",
  cursor: null,
  message_list: [],
  interlocutor: {
    id: "",
    nickname: "",
    conversation_status: ""
  },
  no_more_data: ""
};

// 存储历史记录聊天信息等
export const initHistoryChatInfo = {
  loading: false,
  cursor: null,
  message_list: [],
  no_more_data: ""
};

// 存储当前点击的用户信息
export const friendInfo = {};
