import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Input, message as AM } from "antd";

import { ChatActions } from "../../../redux/ChatRedux";
import { contentsType, messageType } from "../../../config/constants";
import Upload from "./upload";
import { clickCopy } from "../../../utils";

import Picker from "emoji-picker-react";
import emojiUnicode from "emoji-unicode"

class ChatTextarea extends Component {
  state = {
    messageValue: "",
    file_id: "",
    file: null,
    emojiVisible: false
  };

  handleTextArea = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  handleChosenEmoji = (e, emojiObj) => {
    this.setState({ messageValue: this.state.messageValue + emojiObj.emoji })
    this.handleVisibleChange()
  }

  sendChatMess = (e) => {
    e.preventDefault();
    const { messageValue } = this.state;
    if (!messageValue && !messageValue.trim()) {
      AM.error("不能发送空字符");
      return;
    }
    this.sendMessage(contentsType.text, this.handleEmojiEncode(messageValue));
  };

  handleVisibleChange = () => {
    this.setState({ emojiVisible: !this.state.emojiVisible })
  }

  handleEmojiEncode = message => {
    const emojiRE = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gm;
    return message.replace(emojiRE, val => {
      let _hex = emojiUnicode(val)
      while (_hex.length < 4) {
        _hex = "0" + _hex;
      }
      return "&#x" + _hex + ";";
    });
  }

  render() {
    return (
      <div>
        {this.state.emojiVisible &&
          <Picker
            onEmojiClick={(e, emojiObj) => {
              this.handleChosenEmoji(e, emojiObj);
            }}
            disableSearchBar={true}
            disableSkinTonePicker={true}
            disableAutoFocus={true}
            pickerStyle={{
              position: "absolute",
              bottom: "150px"
            }}
          />
        }
        <Input.TextArea
          className="chat-textarea"
          onChange={(e) => {
            this.handleTextArea("messageValue", e);
          }}
          placeholder="输入信息..."
          onPressEnter={(e) => {
            this.sendChatMess(e);
          }}
          value={this.state.messageValue}
        />

        <Row>
          <Col span={3}>
            <Button
              style={{ float: "left" }}
              onClick={this.handleVisibleChange}
            >
              Emoji
            </Button>
          </Col>
          <Col span={18}>
            <Upload onSuccess={this.uploadSuccess} />
          </Col>
          <Col span={3}>
            <Button
              style={{ float: "right" }}
              onClick={(e) => {
                this.sendChatMess(e);
              }}
            >
              Enter
            </Button>
          </Col>
        </Row>

      </div>
    );
  }

  sendMessage = (msg_type = "txt", content = "") => {
    const {
      chat,
      user
    } = this.props;
    this.props.sendMessage(msg_type, chat, content, () => {
      this.setState({
        messageValue: "",
        file_id: "",
        file: null
      });
    });
    clickCopy(content);
  };

  uploadSuccess = (file, { file_id }) => {
    this.setState({ file, file_id });
    const {
      chat
    } = this.props;
    this.sendMessage("image", file_id);
  };
}

export default connect(
  (state) => ({ user: state.user, chat: state.chat }),
  (dispatch) => ({
    sendMessage: (msg_type = "txt", chat, msgStr, cb) =>
      dispatch(ChatActions.onSendMessage(msg_type, chat, msgStr, cb))
  })
)(ChatTextarea);
