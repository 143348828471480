import React from "react";
import { Input, Button, message, Form } from "antd";
import { connect } from "react-redux"  ;
import { UserOutlined, LockOutlined } from "@ant-design/icons"

import history from "../../history";
import { UserActions } from "../../redux/UserRedux";
import "./login.css";
import { ImXUserID, ImXAuthToken, ImUserName, ImPassWord, MqttUrl, Im_Nickname } from "../../config/constants";

class NormalLoginForm extends React.Component {
  loading = false;
  loadingKey = "login...";

  loginSuccess = (user_id, auth_token, nickname) => {
    this.loading = false;
    let { loadingKey: key } = this;
    message.success({ content: "登录成功!", key, duration: 1 });
    localStorage.setItem(ImXUserID, user_id);
    localStorage.setItem(ImXAuthToken, auth_token);
    localStorage.setItem(Im_Nickname, nickname);
    // 重新登录的时候清空 mqtt的相关信息
    localStorage.setItem(ImUserName, "");
    localStorage.setItem(ImPassWord, "");
    localStorage.setItem(MqttUrl, "");
    const { wsService } = require("../../redux/WebSocketRedux");
    wsService.getMqttClient();
    history.replace("/");
  };

  loginFailed = () => {
    this.loading = false;
    let { loadingKey: key } = this;
    message.error({ content: "登录失败!", key, duration: 1 });
  };

  handleSubmit = (values) => {
    if (this.loading) return;
    let { loadingKey: key } = this;
    message.loading({ content: "Loading...", key });
    this.props.loginNow(values, this.loginSuccess, this.loginFailed);
  };

  render() {
    return (
      <Form onFinish={this.handleSubmit} className="login-form">
        <Form.Item name="nickname" rules={[{ required: true, message: "请输入手机号!" }]}>
          <Input
            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="用户名"
          />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: "请输入密码!" }]}>
          <Input
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder="密码"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            登录
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default connect(
  (state) => ({ userInfo: state.user }),
  (dispatch) => ({
    loginNow: (loginParam, onSuccess, onFailed) =>
      dispatch(UserActions.userLogin(loginParam, onSuccess, onFailed))
  })
)(NormalLoginForm);
