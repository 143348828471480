import React, { Component } from "react";
import { Upload, message, Button, Icon } from "antd";
import { ApiHost, ImXAuthToken } from "../../../config/constants";
import { FileImageOutlined } from "@ant-design/icons"

class upload extends Component {
  passProps = {
    name: "file",
    multiple: true,
    action: `${ApiHost}/m_media/upload_media`,
    headers: {
      "X-AUTH-TOKEN": localStorage.getItem(ImXAuthToken)
    },
    onChange: ({ file, fileList }) => {
      this.setState({ fileList });
      if (file.status === "done") {
        this.uploadSuccess(file, fileList);
      } else if (file.status === "error") {
        message.error(`${file.name} file upload failed.`);
      }
    }
  };
  state = {
    fileList: []
  };

  render() {
    return (
      <Upload {...this.passProps} fileList={this.state.fileList} data={{ source_type: "image" }}>
        <Button>
          <FileImageOutlined />
        </Button>
      </Upload>
    );
  }

  listLength = 0;

  uploadSuccess = (file, fileList) => {
    this.props.onSuccess(file, file.response.data);
    this.setFileListEmpty();
  };

  setFileListEmpty = () => {
    this.listLength++;
    if (this.listLength === this.state.fileList.length) {
      this.setState({ fileList: [] });
      this.listLength = 0;
    }
  };
}

export default upload;
