import React, { Component } from "react";
import { Input, Select, Button, Form } from "antd";
import FormItem from "antd/lib/form/FormItem";

export default class InputSearch extends Component {

  render() {
    const {
      ruleBigR,
      readStatus,
      nickname,
      updateProp
    } = this.props;

    return (
      <Form size="small">
        <FormItem label="会员昵称" style={{ marginBottom: "6px" }}>
          <Input value={nickname} onChange={(e) => updateProp("nickname", e.target.value)}></Input>
        </FormItem>
        <FormItem label="身份" style={{ marginBottom: "6px" }}>
          <Select value={ruleBigR} onChange={(value) => updateProp("ruleBigR", value)}>
            <Select.Option value={-1}>全部</Select.Option>
            <Select.Option value={0}>一般用户</Select.Option>
            <Select.Option value={1}>大R用户</Select.Option>
          </Select>
        </FormItem>
        <FormItem label="状态" style={{ marginBottom: "6px" }}>
          <Select value={readStatus} onChange={(value) => updateProp("readStatus", value)}>
            <Select.Option value={-1}>默认</Select.Option>
            <Select.Option value={0}>未读</Select.Option>
            <Select.Option value={1}>已读</Select.Option>
          </Select>
        </FormItem>
        <Form.Item wrapperCol={{ offset: 16, span: 8 }} style={{ marginBottom: "2px" }}>
          <Button type="primary" style={{ width: "100%" }} onClick={() => updateProp("queryVisible", true)}>
            发消息
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
