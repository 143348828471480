import axios from "./axios";
import { deleteInvalidKey } from "../utils";

export const httpGet = (url, data) => {
  const params = deleteInvalidKey(data);
  try {
    return axios.get(url, {
      params
    });
  } catch (error) {
    console.log("error", error);
    Promise.reject(error);
  }
};

export const httpPost = async (url, data = {}) => {
  const params = deleteInvalidKey(data);
  try {
    return axios.post(url, params);
  } catch (error) {
    Promise.reject(error);
  }
};
