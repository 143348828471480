/**
 * 聊天应用骨架
 */
import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Layout } from 'antd'
import Cookies from 'js-cookie'

// import LeftNav from "../components/left-nav";
import Routers from '../router'

export default class App extends Component {
  componentDidMount() {}

  render() {
    const screenType = Cookies.get('screenType')
    return (
      <Layout
        className={[
          'container mini-pattern',
          //   screenType === "fullscreen-exit" ? "mini-pattern" : null,
        ].join(' ')}
      >
        {/* <LeftNav /> */}
        <Switch>
          {Routers.map((item) => (
            <Route
              exact
              path={item.path}
              component={item.components}
              key={item.path}
            />
          ))}
          <Redirect to="/"/>
        </Switch>
      </Layout>
    )
  }
}
