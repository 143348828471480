/**
 * layout 左右布局，适用于 通讯录 与 会话列表 页面
 */
import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Layout, message, Button, Row, Col } from "antd";
import history from "../../history";
import { httpPost } from "../../api";

import Welcome from "../welcome";
import ChatMessage from "../../pages/chat/message";
import { Im_Nickname } from "../../config/constants";
import { user } from "../../redux/UserRedux";
import { Version } from "../../config/version";

const { Sider } = Layout;

class LayoutMain extends Component {
  static propTypes = {
    router: PropTypes.object.isRequired
  };

  // 退出登录的方法
  loginOut = async () => {
    const { real_im_username } = this.props.chat;
    // 获取用户信息
    await httpPost("/m_im/clear_conversations", { im_username: real_im_username });
    const { wsService } = require("../../redux/WebSocketRedux");
    // 断开连接
    wsService.closeClient();
    // 清除数据
    wsService.clearData();
    message.info("客服已下线");
    // 回到登录页面
    history.push("/login");
  }

  render() {
    const {
      router,
      chat: { conversation_id },
      service,
      user
    } = this.props;
    const nickname = localStorage.getItem(Im_Nickname) ? localStorage.getItem(Im_Nickname) : ""
    const state = this.props.user.userState;
    let userState = "离线";
    if (state === "Online") {
      userState = "在线";
    } else if (state === "connecting") {
      userState = "重连中...";
    }
    return (
      <Layout>
        <Row style={{ height: '35px' }} justify="space-between">
          <Col span={9} style={{ textAlign: 'center', color: "#333" }}>
            客服{nickname}为您服务：当前处于 <span style={{ color: state === "Online" ? "#333" : "red" }}>{userState}</span> 状态
          </ Col>
          <Col span={13} style={{ textAlign: 'center' }}>当前服务人数为: {service.service_user_count} / 剩余可服务用户数: {service.rest_service_user_count}</Col>
          <Col span={2}>
            <Button
              type="small"
              onClick={() => {
                this.loginOut();
              }}>退出</Button>
          </Col>
        </Row>
        <Layout>
          <Sider
            className="contacts-list"
            defaultCollapsed="true"
            collapsedWidth="251"
            theme="light"
          >
            <Route path={router.path} component={router.components} />
          </Sider>
          <Layout className="layout-right">
            {conversation_id < 0 ? <Welcome /> : <ChatMessage />}
          </Layout>
        </Layout>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            version {Version}&nbsp;</Col>
        </Row>
      </Layout>
    );
  }
}

export default connect(
  (state) => ({ user: state.user, chat: state.chat, service: state.service }),
  {}
)(withRouter(LayoutMain));
