import React, { Component } from "react";
import Zmage from "react-zmage";
import { decodePicture } from "../../utils";
import { FileImageOutlined, SyncOutlined } from "@ant-design/icons"

class ZImage extends Component {
  state = {
    src: "",
  };

  componentDidMount() {
    this.decode();
  }

  decode = async () => {
    let file_id = this.props.src;
    const src = await decodePicture(file_id);
    // const src = `${MediaHost}${file_id}`;
    this.setState({ src });
  };

  render() {
    const { src } = this.state;
    if (src === this.props.src) {
      return <FileImageOutlined style={{ fontSize: 48 }} />;
    } else if (src.startsWith("blob")) {
      return <Zmage {...this.props} src={this.state.src} />;
    }
    return <SyncOutlined spin />;
  }
}

export default ZImage;
