import React, { Component } from "react";
import { message } from "antd";
import LayoutMain from "../../components/layout";
import Contacts from "./contacts";

import history from "../../history";
import { ImXAuthToken } from "../../config/constants";

class ChatContainer extends Component {
  componentDidMount() {
    if (!localStorage.getItem(ImXAuthToken)) {
      message.info("请登录");
      history.replace("/login");
    }
  }

  render() {
    return <LayoutMain router={{ path: "/", components: Contacts }} />;
  }
}

export default ChatContainer;
